import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EditModal from "./EditModal";
import ReceiptDetails from "../TopUp/ReceiptDetails";
import moment from "moment";
import { deleteContact, getContactHistory } from "../../../services/customerService";
import Swal from "sweetalert2";
import { errorToast, successToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import SendQuery from "../../Shared/HelpAndSupport/SendQuery";
import { getQueryCategories } from "../../../services/helpAndSupportService";

const ContactDetails = ({ customerContactList, selectedContact, masterData, handleDeleteContact, handleUpdateContact, render }) => {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showReceiptDetails, setShowReceiptDetails] = useState(false);
  const [receiptData, setReceiptData] = useState("");
  const [contactHistory, setContactHistory] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [size, setSize] = useState(10);
  const [topUpValue, setTopUpValue] = useState("");
  const [showQuery, setShowQuery] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [transactionId, setTransactionId] = useState(null);
  const navigate = useNavigate();

  let page = 1;

  useEffect(() => {
    if (selectedContact?.id) {
      fetchContactHistory();
    }
    saveSelectedContactToLocalStorage();

    // eslint-disable-next-line
  }, [selectedContact?.id, selectedContact?.carrier_id, selectedContact?.phone_number, render]);

  const saveSelectedContactToLocalStorage = () => {
    const selectedContactData = {
      phoneCode: selectedContact?.phone_code,
      phoneNumber: selectedContact?.phone_number,
      carrierName: selectedContact?.carrier_name,
    };

    // Convert object to JSON string
    const selectedContactDataJSON = JSON.stringify(selectedContactData);

    // Save JSON string to localStorage
    localStorage.setItem("selectedContactData", selectedContactDataJSON);
  };

  const fetchContactHistory = async () => {
    try {
      if (selectedContact?.contact_record_type === "customer") {
        const response = await getContactHistory(page, size);
        setContactHistory(response?.data?.records);
        setHasMoreData(response?.data?.records?.length === size); // Set hasMore based on whether there's more data to load
      } else {
        const response = await getContactHistory(page, size, selectedContact?.id);
        setContactHistory(response?.data?.records);
        setHasMoreData(response?.data?.records?.length === size); // Set hasMore based on whether there's more data to load
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "Something went wrong.");
    }
  };

  async function handleShowDecline(id) {
    try {
      const result = await Swal.fire({
        text: "Are you sure you want to delete this?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes, Delete",
        cancelButtonText: "No, Cancel",
        customClass: {
          confirmButton: "btn  btn-primary me-3",
          cancelButton: "btn  btn-label-secondary",
          content: "info text-primary",
        },
      });

      if (result?.value === true) {
        const response = await deleteContact(id);
        if (response?.success === true) {
          successToast(response?.message);
          handleDeleteContact(response);
        }
      }
    } catch (error) {
      // Handle errors
      errorToast(error?.response?.data?.message || "An error occurred while delete customer.");
    }
  }

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    let response;

    try {
      if (selectedContact?.contact_record_type === "customer") {
        response = await getContactHistory(page, size + 10);
      } else {
        response = await getContactHistory(page, size + 10, selectedContact?.id);
      }

      if (response?.success) {
        const newData = response?.data?.records;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setContactHistory(response?.data?.records);
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [loading, hasMoreData, page, size, currentResultSetLength, selectedContact?.id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("historySelectionComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("historySelectionComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  // Split the string into words
  let words = selectedContact?.name?.split(" ");
  let initials = words?.map((word) => word?.charAt(0));
  let result = initials?.join("");

  const handleTopUpSelect = () => {
    if (selectedContact?.phone_code !== "+93") {
      errorToast("We do not serve this region.");
      return; 
    }
    if(selectedContact?.carrier_id !== 1 && selectedContact?.carrier_id !== 2){
      errorToast("This carrier's top-up feature is coming soon...");
      return; 
    }

    if (topUpValue === "" || topUpValue === null) {
      errorToast("Amount is required");
    } else if (topUpValue < 40) {
      errorToast("Amount must be equal to or greater than 40");
    } else if (topUpValue > 5000) {
      errorToast("Amount must be less than or equal to 5000");
    } else {
      navigate("/customer/topup", { state: { Amount: topUpValue ,carrierId : selectedContact?.carrier_id} });
    }
  };
  

  const handleRaiseQuery = () => {
    setShowQuery(!showQuery);
  };

  useEffect(() => {
    const queryCategories = async () => {
      try {
        const response = await getQueryCategories();

        if (response) {
          setCategoriesData(response);
        }
      } catch (error) {}
    };

    queryCategories();
  }, []);

  const handleNavigateWithState = () => {
    if (selectedContact?.phone_code !== "+93") {
      errorToast("We do not serve this region.");
      return; 
    }
    if(selectedContact?.carrier_id !== 1 && selectedContact?.carrier_id !== 2){
      errorToast("This carrier's top-up feature is coming soon...");
      return; 
    }
    navigate('/customer/topup/products', { state: { carrierId: selectedContact?.carrier_id } });
  };

  return (
    <>
      <div className="tab-content py-0">
        <div className="tab-pane fade show active" id="own_recharge" role="tabpanel">
          <div className="row">
            <div className="col-lg-12 mb-3 pb-3 border-bottom border-bottom-dashed">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-content-center">
                  <div className="avatar avatar-md contact-profile">{selectedContact?.image ? <img src={selectedContact?.image} alt="" className="rounded-circle" /> : <span className="avatar-initial rounded bg-label-primary">{result}</span>}</div>
                  {selectedContact && (
                    <div className="ms-3">
                      <div className="text-dark h4 mb-0">{selectedContact?.name && selectedContact?.name}</div>
                      <h5 className="m-0 me-2">
                        <span className="text-secondary me-1"> {selectedContact?.phone_code && selectedContact?.phone_number && selectedContact?.phone_code + " " + selectedContact?.phone_number}</span> | <span className="h6 ps-2"> </span>
                        {/* {selectedContact?.carrier_name === "Roshan" ? <img src="../../assets/img/roshan-lg.png" alt="Roshan" width="" className="pe-2 ps-3" /> : <img src="../../assets/img/etisalat-lg.png" alt="Etisalat" width="" className="pe-2 ps-3" />}  */}
                        <span className="me-1">{selectedContact?.carrier_name} </span>
                      </h5>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  {selectedContact && (
                    <Link to="#" className="btn btn-outline-primary rounded-pill btn-sm add-mobile-btn" onClick={() => setShowEditProfileModal(true)}>
                      <i className="ti ti-edit" /> Edit
                    </Link>
                  )} 
                  {selectedContact?.contact_record_type === "contact" && (
                    <Link to="#" className="btn btn-outline-primary rounded-pill btn-sm add-mobile-btn ms-1" onClick={() => handleShowDecline(selectedContact?.id)}>
                      <i className="ti ti-trash" /> Delete
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 mb-4">
              <div className="card  mt-0">
                <div className="card-header pb-2 pt-3 d-flex justify-content-between">
                  <h5 className="mb-1 text-nowrap">Top-Up</h5>
                  <div>
                    <span onClick={handleNavigateWithState} style={{cursor: "pointer",color:'#0282C3'}}>Select From Products</span>
                  </div>
                  
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-lg-8">
                      <label htmlFor="" className="form-label">
                        Amount (AFN)
                      </label>
                      <input type="number" className="form-control" id="" placeholder="Enter Top-Up Amount" min={0} max={5000} onChange={(e) => setTopUpValue(e.target.value)} />
                    </div>
                    <div className="col-lg-4 mt-4">
                      {/* <Link to="/customer/topup" type="button" className="btn btn-primary rounded-pill">
                        Recharge
                      </Link> */}
                      <span type="button" className="btn btn-primary rounded-pill" onClick={handleTopUpSelect}>
                        Recharge
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="row">
                <div className="card-header border-bottom border-bottom-dashed py-2 px-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="mb-1 text-nowrap">History</h5>
                  </div>
                </div>
                <InfiniteScroll dataLength={contactHistory?.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : ""} scrollableTarget="historySelectionComponent">
                  <div className="card-body px-3" id="historySelectionComponent" style={{ height: "400px", overflowY: "auto" }}>
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        {contactHistory?.length !== 0 ? (
                          contactHistory?.map((contactHistoryData, index) => (
                            <div className="card histroy-card mb-3" key={contactHistoryData?.id}>
                              <div className="card-header d-flex justify-content-between border-bottom py-2 px-3 align-items-start">
                                <div>
                                  {contactHistoryData?.is_requested_by_contact === 1 && (
                                    <div className=" d-flex justify-content-start me-3 mb-1">
                                      <span className="badge bg-outline-label-secondary">Recharge Request</span>
                                    </div>
                                  )}
                                  <div>
                                    <small className="text-muted">Order ID:</small> <span className="fw-medium me-1 ms-1">{contactHistoryData?.order_id}</span>
                                  </div>
                                  <div>
                                    <small className="text-muted">Recipient No.:</small> <span className="fw-medium me-1 ms-1">{contactHistoryData?.request_id}</span>
                                  </div>
                                  <div className="d-flex">
                                    <small className="text-muted">Date: </small> <span className="fw-medium me-1 ms-2"> {moment(contactHistoryData?.updatedAt).format("DD MMM YY")}</span>
                                  </div>
                                </div>
                                 <div className="text-right">
                                  <div className="d-flex justify-content-end mb-5">
                                    <div className="dropdown">
                                      <button className="btn p-0" type="button" id="history1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ti ti-dots-vertical ti-sm text-blue" />
                                      </button>
                                      <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="history1">
                                        {contactHistoryData?.status === "Successful" && <Link
                                          className="dropdown-item"
                                          to="#"
                                          onClick={() => {
                                            setShowReceiptDetails(true);
                                            setReceiptData(contactHistoryData);
                                          }}
                                        >
                                          Receipt
                                        </Link>}
                                        <Link className="dropdown-item" 
                                          onClick={() => {
                                            setTransactionId(contactHistoryData?.transaction_id);
                                            handleRaiseQuery();}} 
                                          to="" id="emailComposeSidebarLabel">
                                          Raise a Query
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body py-2 px-3">
                                <div className="d-flex justify-content-between">
                                  <div className="chart-statistics">
                                    <h6 className="mb-0 text-muted">Top-Up Amount</h6>
                                    <h5 className="card-title mb-0 pt-1">
                                      {contactHistoryData?.topup_amount_source_currency} <span className="fs-6">{contactHistoryData?.original_currency_type}</span>
                                    </h5>
                                  </div>
                                  <div className="chart-statistics">
                                    <h6 className="mb-0 d-flex justify-content-end text-muted">Amount paid</h6>
                                    <h5 className="card-title mb-0 text-end">
                                      {contactHistoryData?.total_amount_converted_currency} <small>{contactHistoryData?.converted_currency_type}</small>
                                    </h5>
                                    <span className="small">
                                      Top-Up Status <span className={contactHistoryData?.status === "Failed" ? "badge bg-label-danger" : contactHistoryData?.status === "In Process" ? "badge bg-label-warning" : "badge bg-label-success"}>{contactHistoryData?.status}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h5 className="mb-1 text-nowrap">No History found</h5>
                        )}
                      </div>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showQuery && <SendQuery showQueryBox={showQuery} categoriesData={categoriesData?.data} setShowQueryBox={setShowQuery} transactionId={transactionId}/>}
      {showEditProfileModal && <EditModal showEditProfileModal={showEditProfileModal} setShowEditProfileModal={setShowEditProfileModal} selectedContact={selectedContact} masterData={masterData} handleUpdateContact={handleUpdateContact} />}
      {showReceiptDetails && <ReceiptDetails showReceiptDetails={showReceiptDetails} setShowReceiptDetails={setShowReceiptDetails} receiptData={receiptData} />}
    </>
  );
};

export default ContactDetails;
